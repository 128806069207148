import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const openMenu = () => {
    const mainMenu = document.querySelector('.mainMenu');
    mainMenu.style.display = 'flex';
    mainMenu.style.top = '0';
  };

  const closeMenu = () => {
    const mainMenu = document.querySelector('.mainMenu');
    mainMenu.style.top = '-100%';
    setTimeout(() => {
      mainMenu.style.display = 'none';
    }, 1000);
  };

  const menuItems = document.querySelectorAll('nav .mainMenu li a');

  menuItems.forEach(item => {
    item.addEventListener('click', closeMenu);
  });

  return (
    <nav>
      {/* <div className="logo"><h1>Logo</h1></div> */}
      <div className="openMenu" onClick={openMenu}><i className="fa fa-bars"></i></div>
      <ul className="mainMenu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/work">Works</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <div className="closeMenu" onClick={closeMenu}><i className="fa fa-times"></i></div>
      </ul>
    </nav>
  );
};

export default Navbar;
