import React, { useState } from "react";
import "./Header.css";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";

import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const handleImageClick = (redirect) => {
    window.location.href = redirect;
  };

  const [formData, setFormData] = useState({
    to_name: "Rajesh Nakar",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const sendEmail = () => {
    const serviceId = "service_u4xt9ty";
    const templateId = "template_q7zjvof";
    const userId = "GH3Fla7hmyXcfIUfY";

    console.log("Form Data:", formData);

    emailjs.send(serviceId, templateId, formData, userId).then(
      (response) => {
        console.log("Email sent successfully:", response);
        toast("Email sent successfully!");
      },
      (error) => {
        console.error("Email failed to send:", error);
        toast("Email failed to send. Please try again later.");
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => {
      if (type === "radio") {
        const updatedData = { ...prevData, [name]: value };
        console.log(updatedData);
        return updatedData;
      }

      return { ...prevData, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();

    setFormData({
      to_name: "Rajesh Nakar",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      radio: "",
      message: "",
    });
  };

  return (
    <div className="main-container-all-form-contact">
      <div className="all-form-container">
        <div className="header-main-container">
          <div className="header-map-container">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.0442227169715!2d73.04598105963407!3d19.28044299544956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bda93a9706d1%3A0xade4d135586d0c8!2sADESHWAR%20TOWER%2C%20Kamatghar%2C%20Bhiwandi%2C%20Maharashtra%20421302!5e0!3m2!1sen!2sin!4v1706244441759!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-text1">
            <h1>Get In Touch</h1>
            <p>
              Fill up the form our team will get back to you within 12 hours
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="text1-row">
              <div className="text1">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  placeholder="Enter Name"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="text1">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  placeholder="Enter Last Name"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="input-inpt-text1">
              <div className="text2">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="Enter Email Address"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="text2">
                <label>Phone Number</label>
                <input
                  type="Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  placeholder="Enter Phone Number"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="radio-buttons">
                <label className="radio">
                  <input type="radio" name="radio" value="sacredness" />{" "}
                  Sacredness
                  <span></span>
                </label>

                <label className="radio">
                  <input type="radio" name="radio" value="Compability" />{" "}
                  Compability
                  <span></span>
                </label>

                <label className="radio">
                  <input type="radio" name="radio" value="other" /> Harmony
                  <span></span>
                </label>
              </div>

              <div className="radio-buttons2">
                <label className="radio">
                  <input type="radio" name="radio" value="sacredness" />{" "}
                  Invocation
                  <span></span>
                </label>

                <label className="radio">
                  <input type="radio" name="radio" value="Compability" />{" "}
                  Tradition
                  <span></span>
                </label>

                <label className="radio">
                  <input type="radio" name="radio" value="other" /> other
                  <span></span>
                </label>
              </div>

              <div className="text-area-form">
                <label>Message</label>
                <textarea
                  rows="4"
                  cols="100"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="submit-btn-container">
              <button className="sub-btn">Submit</button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
      <div className="footer-contact-page">
        <div
          className="footer-containers"
          onClick={() => handleImageClick("https://www.facebook.com/rajesh.nakar.75?mibextid=LQQJ4d")}
        >
          <h1>FB</h1>
          <div className="collection-footer">
            <p>Facebook</p>
            <h2>Rajesh Nakar</h2>
          </div>
        </div>
        <div
          className="footer-containers"
          onClick={() =>
            handleImageClick(
              "https://www.instagram.com/nakarrajesh1?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            )
          }
        >
          <h1>IG</h1>
          <div className="collection-footer">
            <p>Instagram</p>
            <h2>@_rajesh_nakar</h2>
          </div>
        </div>
        <div className="footer-containers" onClick={()=>handleImageClick("https://wa.me/+919975126961")}>
          <h1>Wa</h1>
          <div className="collection-footer">
            <p>Whatsapp</p>
            <h2>+91 9860852466</h2>
          </div>
        </div>
        <div className="footer-containers" onClick={()=> handleImageClick("https://mail.google.com/mail/u/0/#inbox?compose=CllgCJfrslcsnztvKXSVPZfsLpBMwpLHrTMzvQmqKhBJThqsfXZcDrfWDpTMgRKzvgbkHRbjWqB")}>
          <h1>Gm</h1>
          <div className="collection-footer">
            <p>Gmail</p>
            <h2>rnnakar@gmail.com</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
//npm install --save react-toastify
//npm install emailjs-com
//email js id:-kinguporia@gmail.com
//pss:-jatin234
