import "../Css/CientCounter.css";
import { useState, useEffect } from "react";

export const CientCounter = () => {
  const [clientCount, setClientCount] = useState(0);

  useEffect(() => {
    let intervalId;

    function counter() {
      intervalId = setInterval(() => {
        setClientCount((prevCount) => {
          if (prevCount < 500) {
            return prevCount + 1;
          } else {
            clearInterval(intervalId);
            return prevCount;
          }
        });
      }, 10);
    }

    counter(); // Call the counter function when the component renders

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [yearCount, setYearCount] = useState(0);

  useEffect(() => {
    let intervalId;

    function counter() {
      intervalId = setInterval(() => {
        setYearCount((prevCount) => {
          if (prevCount < 25) {
            return prevCount + 1;
          } else {
            clearInterval(intervalId);
            return prevCount;
          }
        });
      }, 200);
    }

    counter(); // Call the counter function when the component renders

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [workCount, setWorkCount] = useState(0);

  useEffect(() => {
    let intervalId;

    function counter() {
      intervalId = setInterval(() => {
        setWorkCount((prevCount) => {
          if (prevCount < 1000) {
            return prevCount + 1;
          } else {
            clearInterval(intervalId);
            return prevCount;
          }
        });
      }, 5);
    }

    counter(); // Call the counter function when the component renders

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="client-counter-main-container"
      data-aos="fade-down"
      data-aos-delay="50"
      data-aos-once="false"
      data-aos-easing="ease-out"
    >
      <h1 className="client-counter-main-container-heading">
        More than <span className="client_counter">{clientCount}+</span> Clients{" "}
        <span>{yearCount}+</span> years of Experience <span>{workCount}+</span>{" "}
        Work Done
      </h1>
    </div>
  );
};
