import { useLocation } from "react-router-dom";
import React from "react";
import { WorkDetailsComponents } from "../Componenets/WorkDetailsComponents";
import { Helmet } from 'react-helmet'

const WorkDetailsPage = () => {
  const location = useLocation();
  const finalNameOne = location.state.stageOnePartnerOne;
  const finalNameTwo = location.state.stageOnePartnerTwo;
  const finalImage = location.state.stageOneImage;
  const finalVideo = location.state.stageOneVideo;
  const finalDescpOne = location.state.stageOneDescpOne;
  const finalDescpTwo = location.state.stageOneDescpTwo;
  const finalQuote = location.state.stageOneQuote;
  return (
    <div>
      <Helmet>
        <title>{finalNameOne}</title>
        <meta name="description" content={finalDescpOne + finalDescpTwo + finalQuote} />
      </Helmet>
      <WorkDetailsComponents
        finalNameOne={finalNameOne}
        finalNameTwo={finalNameTwo}
        finalImage={finalImage}
        finalVideo={finalVideo}
        finalDescpOne={finalDescpOne}
        finalDescpTwo={finalDescpTwo}
        finalQuote={finalQuote}
      />
    </div>
  );
};

export default WorkDetailsPage;
