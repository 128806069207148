import gmaillogo from '../Assets/gmaillogo.webp';
import calllogo from '../Assets/calllogo.avif';
import srv1 from '../Assets/srv1.png';
import srv2 from '../Assets/srv2.png';
import srv3 from '../Assets/srv3.png';
import srv4 from '../Assets/srv4.png';
import muscan from '../Assets/muscan.png';
import amit from '../Assets/second.png'
import introMain from '../Assets/intromain.jpg'
export default {
    gmaillogo,
    calllogo,
    srv1,
    srv2,
    srv3,
    srv4,
    introMain,
    muscan,
    amit,
}