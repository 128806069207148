import "../Css/Services.css";
import { LeftImgInfo } from "./LeftImgInfo";
import { RightImgInfo } from "./RightImgInfo";
import Media from "../Assets/Media";
import { Video } from "./Video";
export const Services = () => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  function convertToEmbedLink(regularLink) {
    const match = regularLink.match(regex);
    if (match) {
      const videoCode = match[1];
      return `https://www.youtube.com/embed/${videoCode}`;
    }
    return null;
  }
  return (
    <div className="services-main-container">
      <h1
        className="services-main-container-heading"
        data-aos="fade-down"
        data-aos-delay="50"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        "Services"
      </h1>
      <LeftImgInfo
        image={Media.srv1}
        title="Office Vastu"
        description="A harmonious and balanced office environment is crucial for success and productivity. Our Office Vastu consultation focuses on aligning the energy flow within the workspace to enhance creativity, teamwork, and overall efficiency. We analyze the layout, placement of desks, seating arrangements, and the orientation of key elements to ensure a positive and conducive atmosphere for professional growth. With our expertise in Office Vastu, we help businesses create an environment that fosters success and prosperity."
      />
      <RightImgInfo
        image={Media.srv2}
        title="House Vastu"
        description="Your home is a sacred space that influences your well-being and relationships. Our House Vastu consultations provide personalized recommendations to balance the energy flow within your living space. We analyze the architecture, room placements, furniture arrangement, and the direction of various rooms to create a harmonious living environment. Whether you are building a new home or seeking to harmonize your existing space, our House Vastu services aim to bring positive energy, peace, and prosperity into your home."
      />
      <iframe
        // className="work-detail-video"
        width={1120}
        height={630}
        src={convertToEmbedLink("https://youtu.be/VDUgQtDVKuU")}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      <LeftImgInfo
        image={Media.srv3}
        title="Industrial Vastu"
        description="Industrial spaces play a vital role in economic growth, and the energy flow within these spaces can impact productivity. Our Industrial Vastu consultations are tailored to the specific needs of factories, warehouses, and manufacturing units. We analyze the layout, machinery placement, entrance locations, and other key factors to optimize the energy flow for maximum efficiency. With our Industrial Vastu expertise, we assist businesses in creating a positive and prosperous industrial environment that supports growth and success."
      />
      <iframe
        // className="work-detail-video"
        width={1120}
        height={630}
        src={convertToEmbedLink("https://youtu.be/Prs1xt1f2gk")}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      <RightImgInfo
        image={Media.srv4}
        title="Temple Vastu"
        description="Temples are sacred places that hold spiritual significance and cultural importance. Our Temple Vastu services aim to enhance the divine energy within these sacred spaces. We provide guidance on the architectural design, deity placement, sanctum orientation, and overall temple layout to ensure a spiritually uplifting environment. Whether you are constructing a new temple or seeking to improve an existing one, our Temple Vastu consultations are designed to create a tranquil and spiritually enriching atmosphere for devotees."
      />
    </div>
  );
};
