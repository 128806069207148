import { WorkContainer } from "../Componenets/WorkContainer.js"
import '../App.css'
import Footer from '../Componenets/Footer/Footer.js'
import { Helmet } from 'react-helmet'
export const Work = () => {
    return <div className="main-container-works1" style={{ backgroundColor: 'black', color: 'white' }}>
        <Helmet>
            <title>Work Page</title>
            <meta name="description" content="Discover the transformative power of Vastu with Rajesh Nakar on rnvaastuconsultant.com Explore our Work page to see a portfolio of successful projects and satisfied clients. Rajesh Nakar, a renowned Vastu consultant, brings harmony and prosperity through expert Vastu consultations and personalized solutions. Browse our gallery of past projects to see the impact of Vastu in homes, offices, and commercial spaces. Trust Rajesh Nakar to guide you towards a balanced and positive environment. Visit our Work page to learn more!" />

        </Helmet>
        <WorkContainer />
        <Footer />
    </div>
}