import React from "react";
import "./WithoutText.css";
import ImagesData from "../Assets/ImagesData";
const WithoutText = () => {
  return (
    <div className="without-all-container">
      <h1
        className="h1-main-text"
        data-aos="fade-down"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        Effects Without proper Vastu
      </h1>
      <div className="container-all-image-text">
        <div
          className="container-image-without"
          data-aos="fade-right"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <img
            src={ImagesData.badeffects}
            alt="Rajesh Nakar Vastu Shastra Consultancy Indian Vastu Services Vastu Solutions in India Vastu Experts Online Vastu Consultation Services Traditional Vastu Guidance Vedic Vastu Consultancy Ancient Vastu Principles Vastu Remedies in India Authentic Vastu Advice Vastu Specialists Online Holistic Vastu Solutions Vastu Analysis Services Personalized Vastu Consultations Professional Vastu Recommendations Vastu Architecture Consultancy Effective Vastu Tips Customized Vastu Consultancy Vastu Insights and Guidance Vastu for Homes and Businesses in India"
          />
        </div>
        <div
          className="textAllContainer"
          data-aos="fade-left"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <p>Health Challenges</p>
          <p>Financial Instability</p>
          <p>
            <span>Strained Relationships</span>
          </p>
          <p>Carrer Stagnation</p>
          <p>Lack of Peace</p>
          <p>Functional inefficiencies</p>
          <p>Negative Energy Accumulation</p>
          <p>Sleep Disturbances</p>
          <p>Lack of Focus and Clarity</p>
          <p>
            Missed <span>Opportunites</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WithoutText;
