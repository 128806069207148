import React from "react";
import "./textEffects.css";
import ImagesData from "../Assets/ImagesData";
const TextEffects = () => {
  return (
    <div className="main-info-container-right">
      <h1
        className="main-info-heading-small-right"
        data-aos="fade-left"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        good effects of{" "}
        <span className="main-info-heading-big-right">Vastu ? </span>
      </h1>
      <div className="sub-info-container-right">
        <div
          className="info-text"
          data-aos="fade-right"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <p className="info-text-right">
            Vastu Shastra, an ancient Indian architectural philosophy, promotes
            balance and positivity in spaces by aligning layout and design
            elements. It enhances positive energy flow, fostering physical
            health and well-being. Emphasizing natural elements and thoughtful
            placement, Vastu creates environments conducive to success and
            happiness.
          </p>
        </div>
        <div
          className="info-img-container-right"
          data-aos="fade-left"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <img
            src={ImagesData.goodeffects}
            alt="Vastu Shastra Consultancy Indian Vastu Services Vastu Solutions in India Vastu Experts Online Vastu Consultation Services Traditional Vastu Guidance Vedic Vastu Consultancy Ancient Vastu Principles Vastu Remedies in India Authentic Vastu Advice Vastu Specialists Online Holistic Vastu Solutions Vastu Analysis Services Personalized Vastu Consultations Professional Vastu Recommendations Vastu Architecture Consultancy Effective Vastu Tips Customized Vastu Consultancy Vastu Insights and Guidance Vastu for Homes and Businesses in India"
          />
        </div>
      </div>
    </div>
  );
};

export default TextEffects;
