import React from "react";
import "../Css/WorkDetailsComponents.css";

export const WorkDetailsComponents = (props) => {

  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  function convertToEmbedLink(regularLink) {
    const match = regularLink.match(regex);
    if (match) {
      const videoCode = match[1];
      return `https://www.youtube.com/embed/${videoCode}`;
    }
    return null;
  }

  return (
    <div className="main-work-detail-container">
      <h1
        className="work-detail-heading"
        data-aos="fade-down"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        {props.finalNameOne}
      </h1>
      <img
        data-aos="fade-right"
        data-aos-once="false"
        data-aos-easing="ease-out"
        src={props.finalImage}
        alt="Rajesh Nakar Vaastu Shastra Consultancy Indian Vaastu Services Vaastu Solutions in India Vaastu Experts Online Vaastu Consultation Services Traditional Vaastu Guidance Vedic Vaastu Consultancy Ancient Vaastu Principles Vaastu Remedies in India Authentic Vaastu Advice Vaastu Specialists Online Holistic Vaastu Solutions Vaastu Analysis Services Personalized Vaastu Consultations Professional Vaastu Recommendations Vaastu Architecture Consultancy Effective Vaastu Tips Customized Vaastu Consultancy Vaastu Insights and Guidance Vaastu for Homes and Businesses in India"
        className="work-detail-image"
      />
      <p
        className="work-detail-para"
        data-aos="fade-left"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        {props.finalDescpOne} <br /> <br /> {props.finalDescpTwo}
      </p>
      <iframe
        // className="work-detail-video"
        width={1120}
        height={630}
        src={convertToEmbedLink(props.finalVideo)}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
      <div className="work-detail-quote-container">
        <div className="red-line"></div>
        <p className="quote-para">{props.finalQuote}</p>
      </div>
    </div>
  );
};
