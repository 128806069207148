import React from "react";
import "../Information/Information.css";
import ImagesData from "../Assets/ImagesData";

export const Information = (props) => {
  return (
    <div className="main-info-container-left">
      <h1
        className="main-info-heading-small-left"
        data-aos="fade-right"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        <span className="main-info-heading-big">Vastu ? </span> What is it
      </h1>
      <div className="sub-info-container-left">
        <div
          className="info-img-container"
          data-aos="fade-right"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <img
            src={ImagesData.vastuuImg1}
            alt="Vastu Shastra Consultancy Indian Vastu Services Vastu Solutions in India Vastu Experts Online Vastu Consultation Services Traditional Vastu Guidance Vedic Vastu Consultancy Ancient Vastu Principles Vastu Remedies in India Authentic Vastu Advice Vastu Specialists Online Holistic Vastu Solutions Vastu Analysis Services Personalized Vastu Consultations Professional Vastu Recommendations Vastu Architecture Consultancy Effective Vastu Tips Customized Vastu Consultancy Vastu Insights and Guidance Vastu for Homes and Businesses in India"
          />
        </div>
        <div
          className="info-text"
          data-aos="fade-left"
          data-aos-once="false"
          data-aos-easing="ease-out"
        >
          <p className="info-text-left">
            Vastu, also known as Vastu Shastra, is an ancient Indian
            architectural and design philosophy that encompasses principles
            believed to harmonize individuals with their environment
          </p>
        </div>
      </div>
    </div>
  );
};
