import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Home } from "./Pages/Home";

import { Work } from "./Pages/Work";
import { Contact } from "./Pages/Contact";
import WorkDetails from "./Pages/WorkDetails";
import Navbar from "./Componenets/Navbar/Navbar";
import { Helmet } from "react-helmet";
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 500,
      easing: "ease-out",
      offset: 200,
      mirror: true,
      anchorPlacement: "top-bottom",
    });
  }, []);

  useEffect(() => {
    Aos.refresh();
  }, []);
  return (
    <div className="App">
      <Router>
        <Helmet>
          <title>RN Vastu Consultant | Rajesh Nakar</title>
          <meta
            name="description"
            content=" Discover harmony and prosperity with RN Vastu Consultant. Expert guidance in Vastu Shastra for balanced living spaces. Personalized consultations and insightful articles await. Transform your environment, transform your life."
          />
          <meta
            name="keywords"
            content="
Vastu consultancy, Vastu Shastra, Vastu in Bhiwandi, Vastu in India, Vastu near me, Vastu Shastra expert, Vastu consultant in Bhiwandi, Vastu services, Vastu solutions, Vastu tips, Rajesh Nakar, Raju Maharaj, Astrologer, Vastu and astrology, Vastu for home, Vastu for office, Vastu for business, Vastu for prosperity, Vastu for wealth, Vastu for health, Vastu for relationships, Vastu articles, Vastu blog, Vastu resources, Rajesh Nakar Facebook page,Vastu consultation, Vastu analysis, Vastu remedies, Vastu principles, Vastu evaluation, Vastu assessment, Vastu guidance, Vastu expert advice, Vastu assessment services, Vastu site visit, Vastu recommendations, Vastu evaluation report, Vastu for architecture, Vastu for interior design, Vastu for construction, Vastu for real estate, Vastu for property, Vastu for commercial spaces, Vastu for residential spaces, Vastu for apartments, Vastu for flats, Vastu for land, Vastu for factories, Vastu for shops, Vastu for restaurants, Vastu for hotels, Vastu for educational institutions, Vastu for hospitals, Vastu for temples, Vastu for religious places, Vastu workshops, Vastu seminars, Vastu events, Vastu courses, Vastu certification, Vastu training, Vastu knowledge, Vastu articles, Vastu blogs, Vastu resources, Contact us, Get in touch, Reach out, Contact information, Contact details, Contact form, Work samples, Portfolio, Previous projects, Client testimonials, rn vaastu instagram, rn vaastu facebook, rn vaastu whatsapp, rajesh nakar instagram, rajesh nakar facebook, rajesh nakar whatsapp.
"
          />
        </Helmet>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/workdetails" element={<WorkDetails />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
