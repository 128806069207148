import "../Css/Introduction.css";
import React from "react";

export const Introduction2 = (props) => {
  return (
    <div className="intro-container">
      <div
        className="intro-img-container"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        <img
          src={props.image}
          alt="Rajesh Nakar Vastu Shastra Consultancy Indian Vastu Services Vastu Solutions in India Vastu Experts Online Vastu Consultation Services Traditional Vastu Guidance Vedic Vastu Consultancy Ancient Vastu Principles Vastu Remedies in India Authentic Vastu Advice Vastu Specialists Online Holistic Vastu Solutions Vastu Analysis Services Personalized Vastu Consultations Professional Vastu Recommendations Vastu Architecture Consultancy Effective Vastu Tips Customized Vastu Consultancy Vastu Insights and Guidance Vastu for Homes and Businesses in India"
          className="intro-image"
        />
      </div>
      <div
        className="intro-info"
        data-aos="fade-left"
        data-aos-delay="50"
        data-aos-once="false"
        data-aos-easing="ease-out"
      >
        <h1 className="intro-heading">{props.name}</h1>
        <p className="intro-para">{props.description}</p>
        <p className="intro-address">{props.address}</p>
      </div>
      {/* <div className="background-one"></div> */}
    </div>
  );
};
