import img1 from './vastuimg1.jpg';
import img2 from './vastuimg2.jpg';
import img3 from './vastuimg3.jpg';
import img4 from './vastuimg4.jpg';
import directionsImg from '../Assets/directions_image.png';
import vastuuImg1 from '../Assets/vastuuImg1.png';
import goodeffects from '../Assets/goodeffects.png'
import badeffects from '../Assets/badeffects.png'
const ImagesData = [
  {
    id: 1,
    title: "Image 1",
    img: img1,
  },
  {
    id: 2,
    title: "Image 2",
    img: img2,
  },
  {
    id: 3,
    title: "Image 3",
    img: img3,
  },
  {
    id: 4,
    title: "Image 4",
    img: img4,
  },
];


export default { ImagesData, directionsImg, vastuuImg1 ,goodeffects, badeffects};
