import Header from "../Componenets/ContactsComponents/Header/Header.js";
import { Helmet } from 'react-helmet'
export const Contact = () => {
    return <div className="conatact-div">
        <Helmet>
            <title>Contact Page</title>
            <meta name="description" content="Get in touch with Rajesh Nakar, your trusted Vastu consultant, on the Contact page of RNVaastuConsultant.com. Whether you have questions, need advice, or wish to schedule a consultation, our team is here to assist you. Connect with us to bring harmony and prosperity into your space with personalized Vastu solutions. Reach out today and start your journey towards a balanced environment!" />
        </Helmet>
        <Header />
    </div>
}