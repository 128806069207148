import React from "react";
import "../Css/WorkContainer.css";
import { Works } from "./Works";
import Media from "../Assets/Media";

export const WorkContainer = () => {
  const data = [
    {
      partner_one: "Muscan Polycontainer LTD.",
      partner_two: "KIRAN",
      image: Media.muscan,
      video: "https://youtu.be/VDUgQtDVKuU",
      descp_one:
        "Experience the transformative power of Vaastu consultancy as Rajesh Nakar guides Dhairia through a journey of alignment and prosperity at Muscan Polycontainer LTD. Witness how ancient principles seamlessly merge with modern industrial needs, creating an environment where success flows effortlessly and abundance thrives.",
      descp_two:
        "Accompany Dhairia and Rajesh Nakar as they co-create a space where positive energies converge to drive productivity and growth at Muscan Polycontainer LTD. From optimizing layouts to enhancing spatial arrangements, every decision is imbued with the wisdom of Vaastu, fostering a workplace that radiates harmony and success.",
      quote:
        " In the symphony of alignment, Rajesh Nakar orchestrates the perfect harmony of energies at Muscan Polycontainer LTD., weaving prosperity and positivity into every corner. Here's to a future illuminated by the guiding light of Vaastu wisdom. 🌟✨ #VaastuConsultancy",
    },
    {
      partner_one: "Amit and Yuga",
      partner_two: "KIRAN",
      image: Media.amit,
      video: "https://youtu.be/Prs1xt1f2gk",
      descp_one: `Witness the transformative journey of Amit and Yuga as they find harmony and peace through Vaastu consultancy by Rajesh Nakar. From overcoming past challenges to embracing newfound joy, together they build a future filled with love, unity, and prosperity.`,
      descp_two: `Explore the radiant abode of Amit and Yuga, where Vaastu principles intertwine with cherished dreams to create a sanctuary of happiness and fulfillment. Every room resonates with laughter, every corner whispers of shared aspirations, forging a bond that grows stronger with each passing moment.`,
      quote:
        "In the symphony of alignment, Amit and Yuga rediscover the melody of their lives, guided by the profound wisdom of Vaastu consultancy by Rajesh Nakar. Here's to a future illuminated by love, peace, and endless possibilities. 💖✨ #VaastuConsultancy",
    },

    // Add other data objects here
  ];

  return (
    <div className="workcontainer-main-container">
      {data.map((entry, index) => {
        return (
          <Works
            image={entry.image}
            video={entry.video}
            partner_one={entry.partner_one}
            partner_two={entry.partner_two}
            descp_one={entry.descp_one}
            descp_two={entry.descp_two}
            quote={entry.quote}
          />
        );
      })}
    </div>
  );
};
