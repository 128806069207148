import React from "react";
import "./Introduction.css";
import ImagesData from "../Assets/ImagesData";

export const Introduction1 = () => {
  return (
    <div className="main-directions-div">
      <div className="right-div">
        <p
          className="directions-text"
          data-aos="fade-left"
          data-aos-delay="2500"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
        >
          Positive
        </p>
      </div>

      <div className="middle-div">
        <div className="middle-top">
          <p
            className="directions-text"
            data-aos="fade-down"
            data-aos-delay="2500"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
          >
            Embrace
          </p>
        </div>

        <div className="middle-center">
          <img
            className="main-rotating-image"
            data-aos="fade"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
            src={ImagesData.directionsImg}
            alt="Vastu Shastra Consultancy Indian Vastu Services Vastu Solutions in India Vastu Experts Online Vastu Consultation Services Traditional Vastu Guidance Vedic Vastu Consultancy Ancient Vastu Principles Vastu Remedies in India Authentic Vastu Advice Vastu Specialists Online Holistic Vastu Solutions Vastu Analysis Services Personalized Vastu Consultations Professional Vastu Recommendations Vastu Architecture Consultancy Effective Vastu Tips Customized Vastu Consultancy Vastu Insights and Guidance Vastu for Homes and Businesses in India"
          />
        </div>

        <div className="middle-bottom">
          <p
            className="directions-text"
            data-aos="fade-up"
            data-aos-delay="2500"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
          >
            Vastu
          </p>
        </div>
      </div>

      <div className="left-div">
        <p
          className="directions-text"
          data-aos="fade-right"
          data-aos-delay="2500"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
        >
          With me
        </p>
      </div>
    </div>
  );
};
