import "../Css/LeftImgInfo.css";

export const LeftImgInfo = (props) => {
  return (
    <div className="leftimginfo-main-container">
      <img
        src={props.image}
        alt="Rajesh Nakar Vaastu Shastra Consultancy Indian Vaastu Services Vaastu Solutions in India Vaastu Experts Online Vaastu Consultation Services Traditional Vaastu Guidance Vedic Vaastu Consultancy Ancient Vaastu Principles Vaastu Remedies in India Authentic Vaastu Advice Vaastu Specialists Online Holistic Vaastu Solutions Vaastu Analysis Services Personalized Vaastu Consultations Professional Vaastu Recommendations Vaastu Architecture Consultancy Effective Vaastu Tips Customized Vaastu Consultancy Vaastu Insights and Guidance Vaastu for Homes and Businesses in India"
        className="leftimginfo-image"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-once="false"
      />
      <div
        className="leftimginfo-container"
        data-aos="fade-left"
        data-aos-delay="50"
        data-aos-once="false"
      >
        <h1 className="leftimginfo-heading">{props.title}</h1>
        <p className="leftimginfo-description">{props.description}</p>
      </div>
    </div>
  );
};
