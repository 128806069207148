import { CientCounter } from "../Componenets/CientCounter";
import { Services } from "../Componenets/Services";
import { StickyWatsapp } from "../Componenets/StickyWatsapp";
import Media from "../Assets/Media";
import '../Css/StickyWatsapp.css'
import { Introduction2 } from "../Componenets/Introduction";
import { Introduction1 } from "../Componenets/Introduction/Introduction";
import { Information } from "../Componenets/Information/Information";
import TextEffects from "../Componenets/textEffects/TextEffects";
import WithoutText from "../Componenets/withoutTextEffects/WithoutText";
import Footer from "../Componenets/Footer/Footer";
import { Helmet } from 'react-helmet'
export const Home = () => {

  return (
    <div>
      <Helmet>
        <title>RN Vastu Consultant | Rajesh Nakar</title>
        <meta
          name="description"
          content=" Discover harmony and prosperity with RN Vastu Consultant. Expert guidance in Vastu Shastra for balanced living spaces. Personalized consultations and insightful articles await. Transform your environment, transform your life."
        />
      </Helmet>
      <div className="sticky-container">
        <StickyWatsapp
          class="badge-1"
          image_class="badge-image green"
          image={Media.gmaillogo}
          redirect="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJfrslcsnztvKXSVPZfsLpBMwpLHrTMzvQmqKhBJThqsfXZcDrfWDpTMgRKzvgbkHRbjWqB"
        />
        <StickyWatsapp
          class="badge-2"
          image_class="badge-image blue"
          image={Media.calllogo}
          redirect="tel:+919975126961"
        />
      </div>
      <Introduction1 />
      <Information />
      <TextEffects />
      <WithoutText />
      <Introduction2
        name="Rajesh Nakar"
        image={Media.introMain}
        description="Welcome to Rajesh Nakar Vastu Consultancy, where I bring a wealth of experience and a passion for Vastu Shastra to create harmonious living and working spaces. With a focus on blending traditional principles with modern insights, I offer personalized consultations to enhance the energy and balance of your environment. Let's embark on a transformative journey together, unlocking the potential for prosperity and well-being in your life."
        address="Bhiwandi-Maharashtra"
      />
      {/* <Video video={Media.vid} /> */}
      <Services />
      <CientCounter />
      <Footer />
    </div>
  );
};
